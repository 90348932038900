import css from "./EnhancedDescription.module.css";

const EnhancedDescriptionBox = ({ info, type = 0 }) => {
  if (!info || !info.image) return null;

  const isVideoFile = (url) => {
    const videoExtensions = ['.mp4', '.webm', '.ogg'];
    return videoExtensions.some(extension => url.endsWith(extension));
  };

  const isVideo = isVideoFile(info.image);

  return (
    <div className={css.enhancedWrapper}>
      <div className={type === 0 ? css.contentContainer : css.contentContainerAlt}>
        <div className={css.mobileTitle} dangerouslySetInnerHTML={{ __html: info.title }} />
        <div className={css.mediaWrapper}>
          {isVideo ? (
            <video className={css.mediaVideo} src={info.image} alt="video" controls autoPlay muted />
          ) : (
            <img src={info.image} alt="description" className={css.mediaImage} />
          )}
        </div>
        <div className={css.descriptionBlock}>
          <div className={css.title} dangerouslySetInnerHTML={{ __html: info.title }} />
          <h1 className={css.subTitle}>{info.subtitle}</h1>
          <div className={css.descriptionText} dangerouslySetInnerHTML={{ __html: info.description }} />
        </div>
      </div>
    </div>
  );
};

export default EnhancedDescriptionBox;
