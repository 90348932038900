import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook for routing
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './HomepageBannerNew.css';

const HomepageBannerNew = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);
  const navigate = useNavigate(); // Initialize the useNavigate hook

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const images = [
    { url: "/assets/images/Glam_Shot.jpeg", route: "/product/glamshot", title:"Glamshot" },
    { url: "/assets/images/audio_wallpaper.JPG", route: "/product/audiobook", title:"Audio Guest Book" },
    { url: "/assets/images/photobooth.JPEG", route: "/product/photobooth", title: "Photobooth" },
  ];

  return (
    <div>
      {isMobile ? (
        <div className="HomepageBannerNew_mobile-container">
          <Swiper
            modules={[Navigation, Pagination]}
            spaceBetween={0}
            slidesPerView={1}
            pagination={{ clickable: true, type: 'fraction' }}
            navigation={true}
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <div className="HomepageBannerNew_slide-content" onClick={() => navigate(image.route)}>
                  <img src={image.url} alt={`Slide ${index + 1}`} />
                  <p className="HomepageBannerNew_slide-text">{image.title}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ) : (
        <div className="HomepageBannerNew_desktop-container">
          {images.map((image, index) => (
            <div key={index} className="HomepageBannerNew_image-container" onClick={() => navigate(image.route)}>
              <img src={image.url} alt={`Image ${index + 1}`} />
              <p className="HomepageBannerNew_image-text">{image.title}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default HomepageBannerNew;
