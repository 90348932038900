import React, { useEffect, useRef } from 'react';
import Swiper from 'swiper';
import './posters-slider.scss';
import { Autoplay } from 'swiper/modules';

const PostersSlider = ({ data }) => {
  const swiperRef = useRef(null);

  useEffect(() => {
    const swiperEl = swiperRef.current;
    const postersSwiper = new Swiper(swiperEl, {
      modules: [Autoplay],
      slidesPerView: 1.1,
      centeredSlides: true,
      spaceBetween: 0,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      breakpoints: {
        768: {
          centeredSlides: false,
          slidesPerView: 4,
          spaceBetween: 10,
        },
      },
      loop: true,
      grabCursor: true,
    });

    return () => postersSwiper?.destroy();
  }, [data]);

  return (
    <div className='posterContainer'>
      <div className='posterTitle' dangerouslySetInnerHTML={{ __html: data.title }} />
      <div className="posters-slider">
        <div className="swiper" ref={swiperRef}>
          <div className="swiper-wrapper">
            {data.images.map((item, i) => (
              <div className="swiper-slide" key={i}>
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  <img src={item.path} alt="" />
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostersSlider;
