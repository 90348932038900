import React, { useState } from 'react'; // Import useState for tracking current slide
import Slider from "react-slick";
import css from "./slider.module.css";

const SliderComponent = ({ child }) => {
  const [currentSlide, setCurrentSlide] = useState(1); // Initialize currentSlide state
  const totalSlides = React.Children.count(child); // Calculate the total number of slides using child prop

  const SampleNextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className={css.nextArrow} onClick={onClick}>
        <img src="/assets/icons/icons8-arrow-100.png" alt="Next" />
      </div>
    );
  };

  const SamplePrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className={css.prevArrow} onClick={onClick}>
        <img src="/assets/icons/icons8-arrow-200.png" alt="Previous" />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: current => setCurrentSlide(current + 1), // Update the currentSlide state on slide change
    responsive: [
      {
        breakpoint: 800, // Adjusted to 600px as per your comment
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 500, // New breakpoint for 400px as requested
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className={css.containerSlider}>
      <Slider {...settings}>{child}</Slider>
      <div className={css.slideCountDisplay}>
        {currentSlide} / {totalSlides} {/* Displaying the current slide and total slides */}
      </div>
    </div>
  );
};

export default SliderComponent;
