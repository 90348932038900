import React from 'react';
import 'swiper/css'; // Import Swiper styles
import './brands.css'; // Your custom styles

const Brands = () => {
  const brands1 = [
    { img: "/clients/image 3.svg", link: "#" },
    { img: "/clients/image 9.svg", link: "#" },
    { img: "/clients/image 10.svg", link: "#" },
    { img: "/clients/image 15.svg", link: "#" },
    { img: "/clients/image 16.svg", link: "#" },
  ];

  const brands2 = [
    { img: "/clients/image 17.svg", link: "#" },
    { img: "/clients/image 18.svg", link: "#" },
    { img: "/clients/image 19.svg", link: "#" },
    { img: "/clients/image 20.svg", link: "#" },
    { img: "/clients/image 21.svg", link: "#" },
  ];

  
  const renderBrands = (brands) => (
    <>
      {brands.map((client, index) => (
        <span key={index}>
          <a href={client.link}>
            <img src={client.img} alt={`Brand ${index}`} />
          </a>
        </span>
      ))}
      {/* Repeat for smooth looping */}
      {brands.map((client, index) => (
        <span key={`duplicate-${index}`}>
          <a href={client.link}>
            <img src={client.img} alt={`Brand duplicate ${index}`} />
          </a>
        </span>
      ))}
    </>
  );

  return (
    <div className="brandsContainer">
               {/* <div
          className="brandsTitle"
        >Brands</div> */}
      <div className="marquee">
        <div>{renderBrands(brands1)}</div>
      </div>
      <div className="marquee marqueeReverse">
        <div>{renderBrands(brands2)}</div>
      </div>
    </div>
  );
};

export default Brands;