import css from "./footer.module.css"

const Footer = ({data})=>{
    return<div className={css.parentContainer} >
        <div className={css.Container}>
            <span className={css.title}>Get in touch</span>
            <span className={css.subtitle}>Contact us to know more about all our services so that we can make your events more memorable</span>
            <span className={css.contact}>info@vogueshots.com</span>
            <span className={css.contact}>msakshay010@gmail.com</span>
            <span className={css.contact}>+91 99201 89579</span>
            <span className={css.contact}>+91 98330 50338</span>
        </div>
        <div className={css.Container}>
            <span className={css.title}>Services</span>
            {data && data.map((e,i)=><span className={css.contact} key={i}>{e.name}</span>)}
        </div>
    </div>
}

export default Footer