import css from "./header.module.css";
import React from "react";
import { Link } from "react-router-dom";

const Header = ({ data, products, externalLinks, id, setid }) => {
  const handlemobilemenu = () => {
    document.querySelector(`#header_textContainer`).style.display = "flex";
  };
  return (
    <div className={css.headerParent}>
      <div className={css.Container}>
        <div className={css.logoContainer}>
          <Link to="/">
            <img
            className={css.vogueShotLogo}
              src={data.logo.url}
              onClick={() => setid("")}
              alt="logo"
            />
          </Link>
        </div>
        <div className={css.hamburger} onClick={handlemobilemenu}>
          <img src="/menuicon.svg" alt="hamburger" />
        </div>
        <div className={css.HeaderTextContainer} id="header_textContainer">
          <div
            className={css.crossButton}
            onClick={() => {
              if (document.documentElement.clientWidth < 900)
                document.querySelector(`#header_textContainer`).style.display =
                  "none";
            }}
          >
            X
          </div>
          {externalLinks &&
            externalLinks.map((e, i) => (
              <Link to={`/${e.slug}`}>
                <span
                  className={`${id === e.slug && css.activeScreen} ${
                    css.HeaderText
                  }`}
                  key={i}
                  onClick={() => {
                    if (document.documentElement.clientWidth < 900)
                      document.querySelector(
                        `#header_textContainer`
                      ).style.display = "none";
                    setid(e.slug);
                  }}
                >
                  {e.name}
                </span>
              </Link>
            ))}
          {products &&
            products.map((e, i) => (
              <Link key={i} to={`/product/${e.slug}`}>
                <span
                  className={`${id === e.slug && css.activeScreen} ${
                    css.HeaderText
                  }`}
                  key={i}
                  onClick={() => {
                    setid(e.slug);
                    if (document.documentElement.clientWidth < 900) {
                      document.querySelector(
                        `#header_textContainer`
                      ).style.display = "none";
                    }
                  }}
                >
                  {e.name}
                </span>
              </Link>
            ))}
          {data.content &&
            data.content.map((e, i) => (
              <Link key={i} to={`/${e.slug}`}>
                <span
                  className={`${id === e.slug && css.activeScreen} ${
                    css.HeaderText
                  }`}
                  key={i}
                  onClick={() => {
                    setid(e.slug);
                    if (document.documentElement.clientWidth < 900) {
                      document.querySelector(
                        `#header_textContainer`
                      ).style.display = "none";
                    }
                  }}
                >
                  {e.name}
                </span>
              </Link>
            ))}
          <div className={css.contactButton} onClick={() => setid("")}>
            <a className={css.contactButtonLink} href="https://wa.me/919920189579">Contact Us</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
